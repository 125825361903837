var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"emp",attrs:{"hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Edit Profile")]),_c('v-spacer')],1),_c('v-list',{attrs:{"three-line":"","subheader":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Employee Details")]),_c('v-list-item-subtitle',[_vm._v("Please make sure to fill all required fields")])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Password")]),_c('v-list-item-subtitle',[_vm._v("Leave Password Empty if you do not want to change it")])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"three-line":"","subheader":""}},[_c('v-list-item',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('v-form',[_c('v-container',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Name","rules":{
                      required: true,
                      regex: _vm.regex.name
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Name","required":"","color":"theme"},model:{value:(_vm.profile.name),callback:function ($$v) {_vm.$set(_vm.profile, "name", $$v)},expression:"profile.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Username","rules":{
                      required: true,
                      regex: _vm.regex.username
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Username","required":"","color":"theme"},model:{value:(_vm.profile.username),callback:function ($$v) {_vm.$set(_vm.profile, "username", $$v)},expression:"profile.username"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":{
                      required: false,
                      regex: _vm.regex.phoneNumber
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Phone Number","color":"theme"},model:{value:(_vm.profile.phoneNumber),callback:function ($$v) {_vm.$set(_vm.profile, "phoneNumber", $$v)},expression:"profile.phoneNumber"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Email Address","rules":{
                      required: false,
                      regex: _vm.regex.email
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email Address","color":"theme"},model:{value:(_vm.profile.emailAddress),callback:function ($$v) {_vm.$set(_vm.profile, "emailAddress", $$v)},expression:"profile.emailAddress"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Password","rules":{
                      required: false,
                      regex: _vm.regex.password
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"password","label":"Password","color":"theme"},model:{value:(_vm.profile.password),callback:function ($$v) {_vm.$set(_vm.profile, "password", $$v)},expression:"profile.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":{
                      required:false,
                      confirmPassword:true,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":_vm.profile.password != _vm.profile.confirmPassword ? ['password is not identical to confirmation']: [],"type":"password","label":"Confirm Password","color":"theme"},model:{value:(_vm.profile.confirmPassword),callback:function ($$v) {_vm.$set(_vm.profile, "confirmPassword", $$v)},expression:"profile.confirmPassword"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","min-width":"150","loading":_vm.loading,"disabled":invalid || _vm.loading},on:{"click":_vm.submit}},[_vm._v("Submit")])],1)],1)],1)],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }