<template>
  <v-dialog v-model="dialog.show" hide-overlay transition="dialog-bottom-transition" content-class="emp4">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Edit Profile</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-list three-line subheader>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Admin Details</v-list-item-title>
            <v-list-item-subtitle>Please make sure to fill all required fields</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Password</v-list-item-title>
            <v-list-item-subtitle>Leave Password Empty if you do not want to change it</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list three-line subheader>
        <v-list-item>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form>
              <v-container class="py-0">
                <v-row>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Name"
                      :rules="{
                        required: true,
                        regex: regex.name
                      }"
                    >
                      <v-text-field
                        v-model="profile.name"
                        :error-messages="errors"
                        label="Name"
                        required
                        color="theme"
                      />
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Username"
                      :rules="{
                        required: true,
                        regex: regex.username
                      }"
                    >
                      <v-text-field
                        v-model="profile.username"
                        :error-messages="errors"
                        label="Username"
                        required
                        color="theme"
                      />
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Phone Number"
                      :rules="{
                        required: false,
                        regex: regex.phoneNumber
                      }"
                    >
                      <v-text-field
                        v-model="profile.phoneNumber"
                        :error-messages="errors"
                        label="Phone Number"
                        color="theme"
                      />
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Email Address"
                      :rules="{
                        required: false,
                        regex: regex.email
                      }"
                    >
                      <v-text-field
                        v-model="profile.emailAddress"
                        :error-messages="errors"
                        label="Email Address"
                        color="theme"
                      />
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Password"
                      :rules="{
                        required: false,
                        regex: regex.password
                      }"
                    >
                      <v-text-field
                        v-model="profile.password"
                        :error-messages="errors"
                        type="password"
                        label="Password"
                        color="theme"
                      />
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Confirm Password"
                      :rules="{
                        required:false,
                        confirmPassword:true,
                      }"
                    >
                      <v-text-field
                        v-model="profile.confirmPassword"
                        :error-messages="profile.password != profile.confirmPassword ? ['password is not identical to confirmation']: []"
                        type="password"
                        label="Confirm Password"
                        color="theme"
                      />
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      color="primary"
                      min-width="150"
                      :loading="loading"
                      :disabled="invalid || loading"
                      @click="submit"
                    >Submit</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </validation-observer>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>
<script>
import { required, digits, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
import Api from "@/api/api/index";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})"
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty"
});

extend("regex", {
  ...regex,
  message: "{_field_} is not correct"
});
export default {
  methods: {
    async submit() {
      const self = this;
      this.loading = true
      this.actions.loading = true
      try {
        const user = (await Api.post("user/update/profile", {
          user_id: this.profile.user_id,
          name: this.profile.name,
          phone: this.profile.phoneNumber,
          email: this.profile.emailAddress,
          password: this.profile.password,
          username: this.profile.username
        })).data.result.user
        console.log(user)
        let notification = {
          msg: "Profile Updated",
          color: "success"
        };
        self.$store.dispatch("user/notify", notification);
        self.dialog.show = false;
        self.$emit("updated", user);
      } catch (err) {
          let notification = {
            msg:
              err.response?.data?.error?.msg || "Error, please try again later",
            color: "error"
          };
          self.$store.dispatch("user/notify", notification);
      }
      this.loading = false
      this.actions.loading = false
    },
  },
  watch: {
  },
  data() {
    return {
      regex: {
        password: /^[a-zA-z0-9_\-\!\@\#\$\%\^\&\*\(\)\+\<\>\.\?\,\;\|]{4,20}$/,
        username: /^[a-zA-z_][a-zA-z0-9_\-]{3,}$/,
        url: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
        phoneNumber: /^((\+|00)\s?\d{1,3}\s?)?(\(?\d{2,3}\)?)(\-|\s)?(\d{3}(\-|\s)?\d{4})$/,
        name: /^(?:[a-zA-Z]{3,20})(?:\s{1,2}[a-zA-Z]{1,20}){1,3}$/,
        email: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
      },
      loading:false,
    };
  },
  props: {
    actions:{
      type:Object,
      default:()=>{
        return {
          loading:false,
        }
      }
    },
    profile:{
      user_id: "",
      name: "",
      username: "",
      phoneNumber: "",
      emailAddress: "",
      password: "",
      confirmPassword: ""
    },
    dialog: {
      type: Object,
      default: () => ({ show: false })
    }
  },
  created() {
    extend("confirmPassword", {
      validate: val => {
        if (val != this.profile.password) {
          return "{_field_} is not identical to password";
        } else {
          return true;
        }
      },
      message: "{_field_} is not identical to password"
    });
  },
  computed: {
    show() {
      return this.dialog.show;
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    
  }
};
</script>

<style>
.emp{
     
    
    
   
    
}
</style>